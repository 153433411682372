module.exports = {
  pathPrefix: '',
  siteUrl: 'https://mircea.solutions',
  siteTitle: 'Mircea Adam',
  siteDescription: 'Portfolio site of an Engineer.',
  author: 'Mircea Adam',
  postsForArchivePage: 3,
  defaultLanguage: 'en',
  disqusScript: process.env.DISQUS_SCRIPT || 'https://mircea-solutions.disqus.com/embed.js',
  pages: {
    home: '/',
    blog: 'blog',
    tag: 'tags',
    contact: 'contact',    
  },
  social: {
    facebook: 'https://www.facebook.com/mircea.adam',
    linkedin: 'https://www.linkedin.com/in/mircea-adam-038a3232',
    soundcloud: 'https://soundcloud.com/mirceaadam/sets/best-of-me',
    rss: '/rss.xml',
  },
  contactFormUrl: process.env.CONTACT_FORM_ENDPOINT || 'https://getform.io/f/39e04300-3b5b-4cd3-8019-af0b09d87a03',
  googleAnalyticTrackingId: process.env.GA_TRACKING_ID || 'G-RNJHV5J2ZF',
  tags: {
    aws: {
      name: 'aws',
      description: 'Stuff I am creating in AWS',
      color: '#f0da50',
    },
    infra: {
      name: 'infra',
      description: 'Infrastructure I am thinkering with',
      color: '#90c53f',
    },
    music: {
      name: 'music',
      description: 'Music I listen to or create in my spare time',
      color: '#257acc',
    },
    travel: {
      name: 'travel',
      description: 'My Travels & Experiences',
      color: '#61dbfa',
    },
    vinyl: {
      name: 'vinyl',
      description: 'I love vinyl music.',
      color: '#6f309f',
    },
    devops: {
      name: 'devops',
      description: 'DevOps Stuff I am thinkering with',
      color: '#dd3431',
    },
    projects: {
      name: 'projects',
      description: 'Projects I worked on for fun and are published / live',
      color: '#43ace0',
    },
    sample: {
      name: 'sample',
      description: 'My samples / bootlegs of my creations from Reason Studios',
      color: '#f9c646',
    },
    gardening: {
      name: 'gardening',
      description: 'Personal Gardening Experiments.',
      color: '#f9c646',
    },    
    stream: {
      name: 'stream',
      description: 'In case I do streaming..',
      color: '#f9c647',
    }, 
  },
};
